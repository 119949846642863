@import "colors";

.enthemo-table > table {
  padding: 16px !important;

  thead {
    
    th {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;

      text-transform: uppercase !important;

      font-family: "Excon-Medium" !important;
      font-size: 14px !important;

      &:first-child {
        border-top-left-radius: 8px !important;
        padding-left: 1.5rem !important;
      }

      &:last-child {
        border-top-right-radius: 8px !important;
        padding-right: 1.5rem !important;
      }

      background-color: map-get($map: $theme-colors, $key: "golden") !important;
    }
  }

  tbody > tr > td {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;

    vertical-align: middle !important;

    &:first-child {
      padding-left: 1.5rem !important;
    }

    &:last-child {
      padding-right: 1.5rem !important;
    }
  }

  tbody > tr {
    &:last-child > td {
      &:first-child {
        border-bottom-left-radius: 8px !important;
      }

      &:last-child {
        border-bottom-right-radius: 8px !important;
      }
    }
  }
}

.table > :not(caption) > * > * {
  border: none !important;
}

/** NGX-PAGINATION **/

.ngx-pagination {
  margin-left: 0;
  margin-bottom: 1rem;
}
.ngx-pagination::before,
.ngx-pagination::after {
  content: " ";
  display: table;
}
.ngx-pagination::after {
  clear: both;
}
.ngx-pagination li {
  margin-right: 0.0625rem;
  border-radius: 0;
}
.ngx-pagination li {
  display: inline-block;
}
.ngx-pagination a,
.ngx-pagination button {
  color: #0a0a0a;
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 0;
}
.ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: #e6e6e6;
}
.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  background: map-get($map: $theme-colors, $key: "tomato");
  color: #fefefe;
  cursor: default;
}
.ngx-pagination .disabled {
  padding: 0.1875rem 0.625rem;
  color: #cacaca;
  cursor: default;
}
.ngx-pagination .disabled:hover {
  background: transparent;
}
.ngx-pagination a,
.ngx-pagination button {
  cursor: pointer;
}
.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: "«";
  display: inline-block;
  margin-right: 0.5rem;
}
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: "»";
  display: inline-block;
  margin-left: 0.5rem;
}
.ngx-pagination .show-for-sr {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
.ngx-pagination .small-screen {
  display: none;
}
@media screen and (max-width: 601px) {
  .ngx-pagination.responsive .small-screen {
    display: inline-block;
  }
  .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
    display: none;
  }
}
