$card-border-width: 0px !important;

.card {
  box-shadow: 0px 0px 10px #4d6c7c1a !important;
  border-radius: 8px !important;
}

.card-body {
  padding: 1.75rem !important;
}

.no-shadow {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

.less-padding {
  padding: 1rem !important;
}
