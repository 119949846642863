@import "colors";

@font-face {
  font-family: "Excon-Black";
  src: url("../../fonts/excon/Excon-Black.otf") format("opentype");
}

@font-face {
  font-family: "Excon-Bold";
  src: url("../../fonts/excon/Excon-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Excon-Light";
  src: url("../../fonts/excon/Excon-Light.otf") format("opentype");
}

@font-face {
  font-family: "Excon-Medium";
  src: url("../../fonts/excon/Excon-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Excon-Regular";
  src: url("../../fonts/excon/Excon-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Excon-Thin";
  src: url("../../fonts/excon/Excon-Thin.otf") format("opentype");
}

body {
  font-family: "Excon-Regular" !important;
}

h1 {
  font-family: "Excon-Bold" !important;
  font-size: 72px !important;
}

h2 {
  font-family: "Excon-Bold" !important;
  font-size: 48px !important;
}

h3 {
  font-family: "Excon-Bold" !important;
  font-size: 36px !important;
}

h4 {
  font-family: "Excon-Bold" !important;
  font-size: 24px !important;
}

h5 {
  font-family: "Excon-Bold" !important;
  font-size: 18px !important;
}

.t-bold-19{
  font-family: "Excon-Bold" !important;
  font-size: 19px !important;
}

.t-subtitle {
  font-family: "Excon-Regular" !important;
  font-size: 24px !important;
}

.t-info {
  font-family: "Excon-Regular" !important;
  font-size: 18px !important;
}

.t-section {
  font-family: "Excon-Bold" !important;
  font-size: 14px !important;
  color: map-get($map: $theme-colors, $key: "section") !important;
}

.t-text {
  font-family: "Excon-Regular" !important;
  font-size: 14px !important;
}

.t-details {
  font-family: "Excon-Regular" !important;
  font-size: 12px !important;
  color: #4d6c7c !important;
}

.t-medium-18 {
  font-family: "Excon-Medium" !important;
  font-size: 18px !important;
}

.t-medium-16 {
  font-family: "Excon-Medium" !important;
  font-size: 16px !important;
}

.t-regular-12 {
  font-family: "Excon-Regular" !important;
  font-size: 12px !important;
}

.font-bold {
  font-family: "Excon-Bold" !important;
}

.t-bold-12 {
  font-family: "Excon-Bold" !important;
  font-size: 12px !important;
}

.t-bold-16 {
  font-family: "Excon-Bold" !important;
  font-size: 16px !important;
}

@each $color, $value in $theme-colors {
  .underline-#{$color}::after {
    content: "";
    position: absolute;
    background-color: $value;
    width: 100%;
    left: 0;
    bottom: 8px;
    z-index: 0 !important;
    height: 18px;
    border-top-left-radius: 13px;
    border-top-right-radius: 32px;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 13px;
  }
}
