@import "colors";

$nav-link-color: black !important;

$nav-tabs-link-active-color: map-get(
  $map: $theme-colors,
  $key: "tomato",
) !important;

$nav-tabs-link-active-bg: map-get(
  $map: $theme-colors,
  $key: "background",
) !important;

$nav-link-hover-color: map-get(
  $map: $theme-colors,
  $key: "tomato",
) !important;

$nav-tabs-border-width: 3px !important;
$nav-tabs-border-radius: 0px !important;

$nav-tabs-link-active-border-color: map-get(
  $map: $theme-colors,
  $key: "tomato",
) !important;

.nav-tabs:not(.flex-column) .nav-link {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.nav-tabs.flex-column .nav-link {
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-left: 3px solid #dee2e6 !important;

  margin-bottom: 0 !important;
  &.active {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;

    border-left: 3px solid map-get($map: $theme-colors, $key: "primary") !important;
  }
}

.nav-tabs.flex-column {
  border-bottom: none !important;
}

.nav-tabs:not(.flex-column) .nav-link.disabled {
  opacity: 0.5 !important;
}

.nav-tabs.flex-column .nav-link.disabled {
  color: #b5babe !important;
}

.nav-link.active {
  font-family: 'Excon-Bold' !important;
}

.dropdown-item.active, .dropdown-item:active
{
  background-color: map-get($map: $theme-colors, $key: "tomato") !important;
}