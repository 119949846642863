.modal-header {
  border-bottom: 0px !important;
}

.modal-header,
.modal-body {
  padding: 24px !important;
}


// https://github.com/valor-software/ngx-bootstrap/issues/1664
// E' un fix temporaneo in attesa (da 4 anni) che ValorSoftware rilasci una versione di ngx-bootstrap che risolva il problema
// (( vergognoso )) 
.modal + .modal {
  background: rgba(0, 0, 0, 0.6);
}