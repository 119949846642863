$enthemo-colors: (
  "golden": #ffc300,
  "tomato": #ff4f4b,
  "light-tomato": #ff4f4b23,
  "sky": #62d9ff,
  "jungle": #00a87e,
  "violet": #7657ff,
  "light-jungle": #00a87e23,
  "light-violet": #7657ff23,
  "background": #f7f9fa,
  "gray": #e6ebed,
  "light-gray": #e6ebed23,
  "white": #ffffff,
  "orange": #ff8001,
  "section": #4a6b7b,
  "text": #101442,
  "light-golden": #fff4cf
);

$theme-colors: map-merge($theme-colors, $enthemo-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

@each $color, $value in $theme-colors {
  .bg-#{$color} {
    background-color: $value !important;
  }
}

@each $color, $value in $theme-colors {
  .text-#{$color} {
    color: $value !important;
  }
}

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}