@import "colors";

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    background-color: $value !important;
    color: $white !important;
    border-radius: 32px !important;
    font-family: "Excon-Medium" !important;
    padding: 7px 30px !important;
  }

  .btn-outline-#{$color} {
    background-color: lighten($value, 30%) !important;
    color: $value !important;
    border: 1px solid lighten($value, 30%) !important;
    border-radius: 32px !important;
    font-family: "Excon-Medium" !important;
    padding: 7px 30px !important;

    &:hover {
      background-color: $value !important;
      color: $white !important;
    }
  }
}

@each $color, $value in $theme-colors {
  .link-#{$color} {
    color: $value !important;
    font-family: "Excon-Bold" !important;
    cursor: pointer !important;
  }

  .link-light-#{$color} {
    color: $value !important;
    cursor: pointer !important;
  }
}

.link-none {
  text-decoration: none !important;
  color: inherit !important;
}

.btn-lg {
  padding: 12px 30px !important;
  font-size: 14px !important;
}

$dropdown-min-width: 15rem!important;
$dropdown-border-width: 0px !important;
$dropdown-border-radius: 5px !important;
.dropdown-menu{
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)!important;
}