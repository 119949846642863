@import "animations";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "colors";

.form-label {
  font-weight: 800;
}

.form-control {
  &.is-invalid {
    animation: shake 0.2s ease-in-out 0s 2;
  }
}

// Rimozione "Powered by Google"
.pac-container:after {
  background-image: none !important;
  height: 0px;
}

// Phone Input (non prende tutta la larghezza altrimenti)
.iti {
  display: block !important;
}

// Maps Place Selector (va sotto il modal altrimenti)
.pac-container {
  z-index: 999999 !important;
}

.ng-select-container {
  height: 38px !important;
}

.form-check-input:checked {
  background-color: map-get($map: $theme-colors, $key: "tomato") !important;
  border-color: map-get($map: $theme-colors, $key: "tomato") !important;
}

.form-check-input-reverse:checked {
  background-color: map-get($map: $theme-colors, $key: "tomato") !important;
  border-color: map-get($map: $theme-colors, $key: "tomato") !important;
}

.form-label {
  font-family: "Excon-Medium" !important;
  font-size: 14px !important;
}

.form-check-label {
  font-family: "Excon-Regular" !important;
}

input,
textarea {
  border-width: 2px !important;
}

.bs-datepicker-head {
  background-color: map-get($map: $theme-colors, $key: "golden") !important;
}

.bs-datepicker-head button {
  color: #000 !important;
}

.theme-green .bs-datepicker-body table td.week span {
  color: map-get($map: $theme-colors, $key: "golden") !important;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: map-get($map: $theme-colors, $key: "golden") !important;
}

.btn-link {
  color: map-get($map: $theme-colors, $key: "tomato") !important;
}

.ng-select .ng-select-container {
  border-color: var(--bs-border-color) !important;
  border-width: 2px !important;
  height: 40px !important;

  border-radius: var(--bs-border-radius) !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: map-get($map: $theme-colors, $key: "light-tomato") !important;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid map-get($map: $theme-colors, $key: "tomato") !important;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: darken(map-get($map: $theme-colors, $key: "tomato"), 4%) !important;
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: map-get($map: $theme-colors, $key: "tomato") !important;
  box-shadow: 0 0 0 0.25rem rgba(255, 99, 71, 0.25) !important;
}

.form-control:focus {
  border-color: map-get($map: $theme-colors, $key: "tomato") !important;
  box-shadow: 0 0 0 0.25rem rgba(255, 99, 71, 0.25) !important;
}

.bs-datepicker {
  box-shadow: 0 0 1px 0 #aaa !important;
}

// @mixin switch($res: "sm") {
//   $index: 1rem;
//   $mainVal: 1rem;

//   @if $res == "md" {
//     $index: 2rem;
//     $mainVal: 1.5rem;
//   } @else if $res == "lg" {
//     $index: 3rem;
//     $mainVal: 2rem;
//   } @else if $res == "xl" {
//     $index: 4rem;
//     $mainVal: 2.5rem;
//   }

//   .form-check-input {
//     height: $mainVal !important;
//     width: calc(#{$index} + 0.75rem) !important;
//     border-radius: $mainVal * 2 !important;
//   }

//   .form-check-label{
//     margin-left: 8px!important;
//   }
// }

// .form-check-input {
//   clear: left !important;
// }

// // YOU CAN PUT ALL RESOLUTION HERE
// // sm - DEFAULT, md, lg, xl

// .form-switch.form-switch-sm {
//   @include switch();
// }

// .form-switch.form-switch-md {
//   @include switch("md");
// }

// .form-switch.form-switch-lg {
//   @include switch("lg");
// }

// .form-switch.form-switch-xl {
//   @include switch("xl");
// }
