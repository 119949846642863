.item-clickable {
  cursor: pointer;
}

hr {
  border-top-width: 2px !important;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 #{"/* rtl:ignore */"};
}

.popover-arrow {
  position: absolute;
}

.tooltip {
  position: absolute;
}

.tooltip-arrow {
  position: absolute;
}
