@import "../../../node_modules/bootstrap/scss/functions.scss";
@import "../../../node_modules/bootstrap/scss/variables.scss";
@import "../../../node_modules/bootstrap/scss/mixins.scss";

// Bootstrap Enthemo
@import "custom/badges";
@import "custom/buttons";
@import "custom/cards";
@import "custom/colors";
@import "custom/modals";
@import "custom/forms";
@import "custom/icons";
@import "custom/navigation";
@import "custom/scrollbars";
@import "custom/tables";
@import "custom/text";
@import "custom/utilities";

// @import "custom/accordions";
// @import "custom/alerts";
// @import "custom/dropdowns";
// @import "custom/fonts";
// @import "custom/progress";
// @import "custom/tabs";
// @import "custom/toasts";

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";

body {
  background-color: map-get($map: $theme-colors, $key: "background") !important;
}
