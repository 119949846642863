@import "colors";

$icons: (
  "2_user",
  "3_user",
  "activity",
  "add_user",
  "arrow-down",
  "arrow-down_2",
  "arrow-down_3",
  "arrow-down_circle",
  "arrow-down_square",
  "arrow-left",
  "arrow-left_2",
  "arrow-left_3",
  "arrow-left_circle",
  "arrow-left_square",
  "arrow-right",
  "arrow-right_2",
  "arrow-right_3",
  "arrow-right_circle",
  "arrow-right_square",
  "arrow-up",
  "arrow-up_2",
  "arrow-up_3",
  "arrow-up_circle",
  "arrow-up_square",
  "bag",
  "bag_3",
  "bookmark",
  "buy",
  "calendar",
  "call",
  "calling",
  "call_missed",
  "call_silent",
  "camera",
  "category",
  "chart",
  "chat",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "chevrons-down",
  "chevrons-left",
  "chevrons-right",
  "chevrons-up",
  "close_square",
  "danger_circle",
  "danger_triangle",
  "delete",
  "discount",
  "discovery",
  "document",
  "download",
  "edit",
  "edit_square",
  "filter",
  "filter_3",
  "folder",
  "game",
  "graph",
  "heart",
  "heart-filled",
  "hide",
  "home",
  "image",
  "image_3",
  "info_square",
  "location",
  "lock",
  "login",
  "logout",
  "message",
  "more_circle",
  "more_square",
  "notification",
  "ok",
  "paper",
  "paper_download",
  "paper_fail",
  "paper_negative",
  "paper_plus",
  "paper_upload",
  "password",
  "play",
  "plus",
  "profile",
  "scan",
  "search",
  "send",
  "setting",
  "shield_done",
  "shield_fail",
  "show",
  "star",
  "swap",
  "ticket",
  "ticket_star",
  "tick_square",
  "time_circle",
  "time_square",
  "unlock",
  "upload",
  "video",
  "voice",
  "voice_3",
  "volume_down",
  "volume_off",
  "volume_up",
  "wallet",
  "work"
);

.ei {
  height: 24px;
  width: 24px;
  display: inline-block;
  background-color: #000;
  mask-size: cover;
  -webkit-mask-size: cover;
  vertical-align: top;
}

@each $icon in $icons {
  .ei-#{$icon} {
    @extend .ei;
    mask: url("../../images/icons/#{$icon}.svg") no-repeat;
    -webkit-mask: url("../../images/icons/#{$icon}.svg") no-repeat;
  }
}

$dimensions: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16);

@each $dimension in $dimensions {
  .ei-#{$dimension}x {
    height: #{calc($dimension / 2)}rem;
    width: #{calc($dimension / 2)}rem;
  }
}

@each $color, $value in $theme-colors {
  .ei-c-#{$color} {
    background-color: $value !important;
  }
}
